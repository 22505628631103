const APP_NAME = "SM Markets Gifts"

const URL = window.location.host.split(":")[0]
const API_TOKEN = process.env.REACT_APP_INTERNAL_API_TOKEN || "apps-internal-mn6;kF6#qA2<jk3,"
const API_KEY = process.env.REACT_APP_SYNC_INTERNAL_API_KEY || "da2-xr67mzpqnfgcjb3b7ltesmstue"
const GRAPH_URL = process.env.REACT_APP_GRAPHQL_URL || "https://graphql-internal.frg-develop.smomni.com/graphql"
const STATIC_REPORTS_URL = process.env.REACT_APP_REPORTS_URL || "https://console.frg-develop.smomni.com/reports"
const STATIC_REPORTS_S3_BUCKET = process.env.REACT_APP_REPORTS_S3_BUCKET || "gift-express-console-static-develop"
const STATIC_REPORTS_S3_PATH = process.env.REACT_APP_REPORTS_S3_BUCKET_PATH || "gift-express-console-static-develop/reports"
const ENV = process.env.REACT_APP_ENV || "develop"

// console.log( "+++++++++++++++++++++++++++++++" )
// console.log( "API_TOKEN:", API_TOKEN )
// console.log( "API_KEY:", API_KEY )
// console.log( "URL:", URL )
// console.log( "GRAPH :", GRAPH_URL )
// console.log( "STATIC REPORTS URL:", STATIC_REPORTS_URL )
// console.log( "STATIC REPORTS BUCKET:", STATIC_REPORTS_S3_BUCKET )
// console.log( "STATIC REPORTS BUCKET PATH:", STATIC_REPORTS_S3_PATH )
// console.log( "ENV :", ENV )
// console.log( "+++++++++++++++++++++++++++++++" )

export {
  APP_NAME,
  URL,
  GRAPH_URL,
  ENV,
  API_TOKEN,
  STATIC_REPORTS_URL,
  STATIC_REPORTS_S3_BUCKET,
  STATIC_REPORTS_S3_PATH,
  API_KEY
}