import { Suspense, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

const drawerWidth = 240;

const openedMixin = (theme) => ({
     width: drawerWidth,
     transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
     }),
     overflowX: 'hidden',
});

const closedMixin = (theme) => ({
     transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
     }),
     overflowX: 'hidden',
     width: `calc(${theme.spacing(7)} + 1px)`,
     [theme.breakpoints.up('sm')]: {
          width: `calc(${theme.spacing(8)} + 1px)`,
     },
});

const DrawerHeader = styled('div')(({ theme }) => ({
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'flex-end',
     padding: theme.spacing(0, 1),
     // necessary for content to be below app bar
     ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
     zIndex: theme.zIndex.drawer + 1,
     transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
     }),
     ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
               easing: theme.transitions.easing.sharp,
               duration: theme.transitions.duration.enteringScreen,
          }),
     }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
     ({ theme, open }) => ({
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
               ...openedMixin(theme),
               '& .MuiDrawer-paper': openedMixin(theme),
          }),
          ...(!open && {
               ...closedMixin(theme),
               '& .MuiDrawer-paper': closedMixin(theme),
          }),
     }),
);

const useStyles = makeStyles((theme) => ({
     appBarSpacer: {
          paddingTop: 50,
          display: "flex",
     },
     appBar: {
          backgroundColor: '#f1f5f1 !important',
          color: '#000000',
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(["width", "margin"], {
               easing: theme.transitions.easing.sharp,
               duration: theme.transitions.duration.leavingScreen,
          }),
     },
     username: {
          fontSize: 10,
          paddingRight: 5
     }
}));

let menus = [
     { label: "Dashboard", icon: <DashboardIcon />, url: "/dashboard" },
     { label: "Report", icon: <AssessmentIcon />, url: "/report" }
]

const adminMenus = [
     { label: "User Management", icon: <PeopleIcon />, url: "/users" }
]

const PrivateLayout = props => {
     const { Component } = props
     const classes = useStyles()
     const theme = useTheme();
     const navigate = useNavigate()
     const [open, setOpen] = useState(false);
     const [anchorEl, setAnchorEl] = useState(null);
     const role = sessionStorage.getItem("role")

     const handleDrawerOpen = () => {
          setOpen(true);
     };

     const handleDrawerClose = () => {
          setOpen(false);
     };

     const handleMenu = (event) => {
          setAnchorEl(event.currentTarget);
     };

     const handleClose = () => {
          setAnchorEl(null);
     };

     const doLogout = () => {
          handleClose()
          sessionStorage.clear();
     }

     const showProfile = () => {
          handleClose()
          navigate("/profile")
     }

     useEffect(() => {
          if (!sessionStorage.getItem("token")) {
               navigate(`/login`)
          }

     })

     return (
          <Box sx={{ display: 'flex' }}>
               <CssBaseline />
               <AppBar position="fixed" open={open}>
                    <Toolbar>
                         <IconButton
                              color="inherit"
                              aria-label="open drawer"
                              onClick={handleDrawerOpen}
                              edge="start"
                              sx={{
                                   marginRight: 5,
                                   ...(open && { display: 'none' }),
                              }}
                         >
                              <MenuIcon />
                         </IconButton>
                         <Box sx={{ flexGrow: 1 }}>
                              <Box
                                   component="img"
                                   sx={{ display: { xs: 'none', md: 'flex' }, mr: 0, height: 30 }}
                                   alt="SM Markets Gifts"
                                   src="https://gifts.smmarkets.ph/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fimages%2Fgiftexpress.0ddc3661094130cadc859cd98f15958a.png&w=1920&q=75"
                              />
                         </Box>
                         <div>
                              <IconButton
                                   size="large"
                                   aria-label="account of current user"
                                   aria-controls="menu-appbar"
                                   aria-haspopup="true"
                                   onClick={handleMenu}
                                   color="inherit"
                              >
                                   <Typography className={classes.username}>{sessionStorage.getItem("username")}</Typography><AccountCircle />
                              </IconButton>
                              <Menu
                                   id="menu-appbar"
                                   anchorEl={anchorEl}
                                   anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                   }}
                                   keepMounted
                                   transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                   }}
                                   open={Boolean(anchorEl)}
                                   onClose={handleClose}
                              >
                                   <MenuItem onClick={showProfile}>Profile</MenuItem>
                                   <MenuItem onClick={doLogout}>Logout</MenuItem>
                              </Menu>
                         </div>
                    </Toolbar>
               </AppBar>
               <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                         <IconButton onClick={handleDrawerClose}>
                              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                         </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                         {menus.map(({ label, icon, url }) => (
                              <ListItem button key={label} disablePadding sx={{ display: 'block' }} component={Link} to={url}>
                                   <ListItemButton
                                        sx={{
                                             minHeight: 48,
                                             justifyContent: open ? 'initial' : 'center',
                                             px: 2.5,
                                        }}
                                   >
                                        <ListItemIcon
                                             sx={{
                                                  minWidth: 0,
                                                  mr: open ? 3 : 'auto',
                                                  justifyContent: 'center',
                                             }}
                                        >
                                             {icon}
                                        </ListItemIcon>
                                        <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                                   </ListItemButton>
                              </ListItem>
                         ))}
                    </List>
                    <Divider />
                    <List>
                         {role === "Admin" && adminMenus.map(({ label, icon, url }) => (
                              <ListItem button key={label} disablePadding sx={{ display: 'block' }} component={Link} to={url}>
                                   <ListItemButton
                                        sx={{
                                             minHeight: 48,
                                             justifyContent: open ? 'initial' : 'center',
                                             px: 2.5,
                                        }}
                                   >
                                        <ListItemIcon
                                             sx={{
                                                  minWidth: 0,
                                                  mr: open ? 3 : 'auto',
                                                  justifyContent: 'center',
                                             }}
                                        >
                                             {icon}
                                        </ListItemIcon>
                                        <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                                   </ListItemButton>
                              </ListItem>
                         ))}
                    </List>
               </Drawer>
               <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div className={classes.appBarSpacer} >
                         <Suspense >
                              <Component />
                         </Suspense>
                    </div>

               </Box>
          </Box>
     );
}

export default PrivateLayout