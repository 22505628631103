import { createTheme } from "@mui/material/styles";
import { deepOrange } from "@mui/material/colors";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'HenrySansRegular'",
    },
  },
  palette: {
    primary: {
      main: "#0071e3",
      contrastText: "#fff",
    },
    secondary: {
      main: deepOrange[400],
      contrastText: "#fff",
    },
  },
});

export default theme;
