import { lazy } from "react"
const Dashboard = lazy(() => import("../../dashboard"))
const Report = lazy(() => import("../../report"))
const Profile = lazy(() => import("../../profile"))
const Users = lazy(() => import("../../user"))
const UserInfo = lazy(() => import("../../user/UserInfo"))

const privateRoutes = [
    {
        Component: Dashboard,
        path: "/dashboard",
    },
    {
        Component: Report,
        path: "/report",
    },
    {
        Component: Profile,
        path: "/profile",
    },
    {
        Component: Users,
        path: "/users",
    },
    {
        Component: UserInfo,
        path: "/user-info/:userId",
    },
    {
        Component: UserInfo,
        path: "/create-user",
    }
    
]

export default privateRoutes;